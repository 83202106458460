import { Controller } from "@hotwired/stimulus"

const SVG_X = `
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" height="24" width="24" fill="none" stroke="currentColor">
    <path d="M18 6 6 18"></path>
    <path d="m6 6 12 12"></path>
  </svg>
`

export default class extends Controller {
  static targets = ["frame"]

  static ensureDialog(type) {
    let dialog = document.querySelector(`dialog:has(#${type})`)
    if (!dialog) {
      dialog = document.createElement("dialog")
      dialog.className = `ic-dialog ic-dialog_${type}`
      dialog.dataset.controller = "dialog"

      const button = document.createElement("button")
      button.className = "ic-dialog__close"
      button.autofocus = true
      button.dataset.action = "dialog#closeDialog"
      button.innerHTML = SVG_X

      const frame = document.createElement("turbo-frame")
      frame.className = type == "modal" ? "ic-dialog__iframe" : "ic-dialog__turboframe"
      frame.id = type
      frame.dataset.dialogTarget = "frame"
      frame.dataset.action = "turbo:before-fetch-response->dialog#handleResponse"

      dialog.append(button)
      dialog.append(frame)
      document.body.append(dialog)
    }

    return dialog
  }

  openDialog(href) {
    const url = new URL(href, location.origin).toString()
    if (this.frameTarget.src != url) {
      this.frameTarget.innerHTML = ""
      Turbo.visit(url, { frame: this.frameTarget.id })
    }

    this.element.showModal()
  }

  closeDialog() {
    this.element.close()
  }

  handleResponse(event) {
    const response = event.detail.fetchResponse
    const location = response.header("Turbo-Frame-Redirect")
    if (location) {
      Turbo.visit(location)
      event.preventDefault()
    }
  }
}
