// app/javascript/trix_config.js

// app/javascript/trix_config.js

const configureTrix = () => {
  // Override the toolbar to only include bold and italic
  Trix.config.toolbar.getDefaultHTML = function() {
    return `
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="Bold" tabindex="-1">Bold</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="Italic" tabindex="-1">Italic</button>
        </span>
      </div>
    `;
  };

  // enforce 500 character limit
  document.addEventListener("DOMContentLoaded", () => {
    // Enforce the 500 character limit
    document.addEventListener("trix-change", (event) => {
      const editor = event.target;
      const content = editor.editor.getDocument().toString();

      // Strip HTML tags to count only the text characters
      const textContent = content.replace(/<[^>]*>/g, '');

      if (textContent.length > 500) {
        // Prevent the change by restoring the previous document state
        event.preventDefault();

        // Truncate content to 500 characters (preserving formatting)
        // This is a bit complex since we need to maintain formatting
        const document = editor.editor.getDocument();
        const allowedLength = 500 - (textContent.length - content.length);
        editor.editor.setSelectedRange([allowedLength, document.getLength()]);
        editor.editor.deleteInDirection("forward");
      }
    });

    // Prevent multiple paragraphs in pasted content
    document.addEventListener("trix-before-paste", (event) => {
      const paste = event.paste;
      const text = paste.string;

      // If the pasted content contains line breaks, prevent the default paste
      if (text.includes("\n")) {
        event.preventDefault();

        // Instead, insert just the first paragraph
        const firstParagraph = text.split("\n")[0];
        const editor = event.target.editor;
        editor.insertString(firstParagraph);
      }
    });

    // Prevent pressing Enter to create a new paragraph
    document.addEventListener("keydown", (event) => {
      if (event.target.matches("trix-editor") && event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
      }
    });
  });
};

export default configureTrix;
